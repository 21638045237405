import React, { useEffect,useState,useContext,useRef,useReducer } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import RecordRTC, { RecordRTCPromisesHandler } from "recordrtc";
import { invokeSaveAsDialog } from "recordrtc";
import './Videoroom.css';

import {  SocketContext  } from "../../../../context/SocketContext";

import { BsCameraVideoOff } from 'react-icons/bs';
import { BsMicMute } from 'react-icons/bs';
import { TbMinusVertical } from 'react-icons/tb';
import { RiFullscreenFill } from 'react-icons/ri';
import { IoIosAlert } from 'react-icons/io';
import { BsFullscreen } from 'react-icons/bs';

import { v4 as uuidV4 } from "uuid";
import { addPeerAction,removePeerAction} from "./peerActions";
import Peer from "peerjs";

import Videochat from './Videochat';
import Videoplayer from './Videoplayer';
import { peersReducer, PeerState } from "./peerReducer";


import raisehand from '../../../../assets/videocall/raisehand.png';
import mic from '../../../../assets/videocall/mic.ico';
import video from '../../../../assets/videocall/video.ico';
import share from '../../../../assets/videocall/share.ico';
import chaticon from '../../../../assets/videocall/chat.ico';
import hand from '../../../../assets/videocall/hand.ico';
import call from '../../../../assets/videocall/call.ico';

import * as actionCreators from "../../../../redux/actions/actionCreators";

const Videoroom = (props) => {

  const navigate = useNavigate();
  const dispatchact = useDispatch();

  const { socket } = useContext(SocketContext);
  const params = useParams();
  const [peers,dispatch] = useReducer(peersReducer, {});

  const videoRef = useRef();

  const devicetype = useSelector((state) => state.deviceReducer.devicetype);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicid = useSelector((state) => state.tstReducer.flag);
  const team = useSelector((state) => state.teamReducer.team);
  const callFlag = useSelector((state) => state.callReducer.callFlag);

  const [chat,setChat] = useState(false);
  const [chatalert,setChatalert] = useState(false);
  const [cameraflag,setCameraflag] = useState(true);
  const [micflag,setMicflag] = useState(true);
  const [recflag,setRecflag] = useState(true);

  const [showHandflag,setShowhandflag] = useState(false);
  const [screenflag,setScreenflag] = useState(true);
  const [innerflag,setinnerflag] = useState(false);
  const [innerflag4,setinnerflag4] = useState(false);
  const [mobiledevice,setMobiledevice] = useState(false);

  const [stream,setStream] = useState();
  const [me,setMe] = useState();

  const [screenSharingId, setScreenSharingId] = useState();

  const [isScreenSharingActive, setIsScreenSharingActive] = useState(false);
  const [screenSharingStream, setScreenSharingStream] = useState(null);
  const [handstatus,setHandstatus] = useState([]);
  const [handstatusval,setHandstatusval] = useState("");

  const [videostramflag,setVideostreamflag] = useState(false);

  const [recorder,setRecorder] = useState();

  let chunks = [];

  let str = "";
  let bb = "";

  const onBackButtonEvent = (e) => {
      e.preventDefault();

      if (window.confirm("Do you want to end call ?")) {
            navigate(`${Object.values(params)[0]}`)
            window.location.reload();
         } else {

        }
   }

  const stopCall = (topicId) => {

    let usersid = team && team.map((item) =>
     {
       let objroom = [
          item.enrolleduserId
        ]
       return objroom
     });

     let userid = userdetails.userid;
     let topicid = topicId;
     let teammember = [].concat(...usersid);

     const data = {
       userid,
       topicid,
       teammember
     }

     socket.emit('end-call',data);
    window.location.reload(false);


  }

  const leaveCall = () => {
     socket.emit('leavecall');
     dispatchact(actionCreators.clearGrpmsg(""));
     navigate('/');
     window.location.reload();
  }

  const showChat = () => {
    setChat(true);
  }

  const hideChat = () => {
    setChat(false);
    setChatalert(false);
  }

  const showHand = () => {

    let handstatus = {
      hand:"false",
      peerid:me._id,
      topicid:topicid,
      userid:userdetails.userid
    };

     socket.emit('show-hand',handstatus);
     setShowhandflag(false);
  }

  const showHandOff = () => {

    let handstatus = {
      hand:"true",
      peerid:me._id,
      topicid:topicid,
      userid:userdetails.userid
    };

     socket.emit('show-hand',handstatus);
     setShowhandflag(true);
  }

  const removePeer = (peerId,userid) => {
    dispatch(removePeerAction(peerId));
    dispatchact(actionCreators.rmvParticipant(userid));
  }

  const startCamera = () => {

    setCameraflag(true);
    stream.getVideoTracks()[0].enabled = true;

    let camstatus = {
       cam:true,
       peerid:me._id,
       topicid:topicid,
       userid:userdetails.userid
     };

     socket.emit('cam-status',camstatus);
  }

  const stopCamera = () => {

    stream.getVideoTracks()[0].enabled = false;
    setCameraflag(false);

    let camstatus = {
       cam:false,
       peerid:me._id,
       topicid:topicid,
       userid:userdetails.userid
     };

     socket.emit('cam-status',camstatus);

  }

  const startMic = () => {

    setMicflag(true);
    stream.getAudioTracks()[0].enabled = true;

    let micstatus = {
        mute:true,
        peerid:me._id,
        topicid:topicid,
        userid:userdetails.userid
    }

     socket.emit('mic-status',micstatus);

  }

  const stopMic = () => {
    stream.getAudioTracks()[0].enabled = false;
    setMicflag(false);

    let micstatus = {
        mute:false,
        peerid:me._id,
        topicid:topicid,
        userid:userdetails.userid
    }

     socket.emit('mic-status',micstatus);

  }

  const startRec = async () => {

     // Initialize the recorder

     setRecflag(false);

     console.log("recorder",recorder);

     recorder.startRecording();


     {/*

       if(recflag == true) {

         recorder = new RecordRTCPromisesHandler(stream, {
            type: 'video'
          });

         console.log("recorder",recorder);
         recorder.startRecording();

      } else {
         recorder.stopRecording();
         let blob = await recorder.getBlob();
         console.log("blob",blob);
      }

  */}

     {/*
       const mimeType = 'video/webm;codecs=vp8,opus';

       if (!MediaRecorder.isTypeSupported(mimeType)) {
        alert('vp8/opus mime type is not supported');
        return;
      }

*/}

     {/*
     const options = {
       audioBitsPerSecond: 128000,
       videoBitsPerSecond: 2500000,
       mimeType: "video/mp4",
     }

    */}

     {/*
      let mediaRecorder = new MediaRecorder(stream, options);
      setListeners(mediaRecorder);
  */}

     {/*

     const mediaRecorder = new MediaRecorder(stream);

     mediaRecorder.start();

     console.log("mediaRecorder",mediaRecorder);

  */}

     {/*
      let recorder = new RecordRTCPromisesHandler(stream, {
          mimeType: 'video/webm',
          bitsPerSecond: 128000
       });

      // Start recording the video

      recorder.startRecording().then(function() {
            console.info('Recording video ...');
        }).catch(function(error) {
           console.error('Cannot start video recording: ', error);
       });


      // release stream on stopRecording
       recorder.stream = stream;

   */}


  }

  const stopRec = async () => {

    await recorder.stopRecording();
    let blob = await recorder.getBlob();
    invokeSaveAsDialog(blob, `random_name.webm`);

     setRecflag(true);

      {/*
     let data = await recorder.stopRecording();

    console.log("data",data);

     const link = document.createElement('a');

     link.style = 'display: none';
     link.href = data;
     link.download = 'recorded_file.mp4';
*/}

      {/*
     let blob = await recorder.getBlob();

     const blobUrl = URL.createObjectURL(blob);
     const link = document.createElement('a');

     link.style = 'display: none';
     link.href = blobUrl;
     link.download = 'recorded_file.mp4';

     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);

     window.URL.revokeObjectURL(blobUrl);
     chunks = [];
   */}

  }

   {/*

  const setListeners = (mediaRecorder) => {
    mediaRecorder.ondataavailable = handleOnDataAvailable;
    mediaRecorder.onstop = handleOnStop;
}

  const handleOnDataAvailable = ({ data }) => {
     console.log("data",data);
      if (data.size > 0) {
        chunks.push(data);
      }
  };

  const handleOnStop = () => {
    saveFile();
  };

  const saveFile = () => {

    const blob = new Blob(chunks);

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.style = 'display: none';
    link.href = blobUrl;
    link.download = 'recorded_file.mp4';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);
    chunks = [];
};

*/}

  const switchStream = (stream) => {
    setStream(stream);
    setScreenSharingId(me?.id || "");
  };

  const toggleScreenShare = (isScreenSharingActive,screenSharingStream = null) => {
   if (isScreenSharingActive) {
     switchVideoTracks(stream);
   } else {
     switchVideoTracks(screenSharingStream);
    }
 };

  const handleScreenShareToggle = async () => {

   if (!isScreenSharingActive) {
    let newstream = null;

    const constraints = {
      audio: false,
      video: true,
    };

    try {
      newstream = await navigator.mediaDevices.getDisplayMedia(constraints);
    } catch (err) {
      console.log("error occurred when trying ");
    }
    if (newstream) {
      setScreenSharingStream(newstream);
      toggleScreenShare(isScreenSharingActive,newstream);
      setIsScreenSharingActive(true);
    }
  } else {
      toggleScreenShare(isScreenSharingActive);
      setIsScreenSharingActive(false);

      // stop screen share stream
      screenSharingStream.getTracks().forEach((t) => t.stop());
      setScreenSharingStream(null);
    }
  };

  const switchVideoTracks = (stream) => {
   videoRef.current.srcObject = stream;

   Object.values(me?.connections).forEach((connection) => {
         const videoTrack = stream
             ?.getTracks()
             .find((track) => track.kind === "video");
         connection[0].peerConnection
             .getSenders()
             .find((sender) => sender.track.kind === "video")
             .replaceTrack(videoTrack)
             .catch((err) => console.error(err));
     });

};

  const shareScreenOff = () => {

    toggleScreenShare(isScreenSharingActive);
    setIsScreenSharingActive(false);

    // stop screen share stream
    screenSharingStream.getTracks().forEach((t) => t.stop());
    setScreenSharingStream(null);

  }

  const goFullscreen = (id) =>  {

    var element = document.getElementById(id);
    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  const handleraisehand = (data) => {

    let obj = {
      peerid:data.peerid,
      value:data.hand
    }

    let dd = handstatus;
    dd.push(data.peerid);

    setHandstatus(dd);
  }

  const handlechatalert = () => {

     if (chat == false) {
        setChatalert(true);
     }
  }


  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);

     if(devicetype.device == "mobile") {
        setMobiledevice(true);
     }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
 }, []);

  useEffect(() => {

     const meId = uuidV4();

     const peer = new Peer(meId, {

           host: '/',
           port: 9000,
           secure:true,
           path: '/peerjs',
           debug: 3,


       config: {'iceServers': [
         { url: 'stun:stun.prioryou.com' },
         { url: 'turn:turn.prioryou.com',  username: "priormds",credential:'priormds@1209call' }
       ]}


    });

     setMe(peer);

    try {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
         setStream(stream);
      });
    } catch (error) {
        console.log("error",error);
      }


    socket.on("room-status", (roomstatus) => {
      console.log("roomstatus",roomstatus);
    });

    socket.on("user-disconnected", removePeer);

  },[])

  useEffect(() => {

    if(me && stream) {

      videoRef.current.srcObject = stream;
      dispatchact(actionCreators.addParticipant(userdetails.userid));

      socket.emit("join-room",
       {roomId:topicid,peerId:me._id,userid:userdetails.userid,
        name:userdetails.username,audio:micflag,video:cameraflag,
        hand:showHandflag,status:'joined'}
      );

      setVideostreamflag(true);

      }

  },[topicid,me,socket,stream]);

  useEffect(() => {

    if(!me) return;
    if(!stream) return;

    socket.on("user-joined", (peerId,userid) => {

      dispatchact(actionCreators.callStart(false));
      dispatchact(actionCreators.addParticipant(userid));

      const call = me.call(peerId,stream);


      if(call != undefined) {

        call.on("stream", (peerStream) => {
            dispatch(addPeerAction(peerId,peerStream));
        });

      }

    })

     me.on('call', (call) => {
      call.answer(stream)
      call.on("stream", (peerStream) => {
         dispatch(addPeerAction(call.peer,peerStream));
      });
    })

  },[me,stream])

  useEffect(() => {

    if(screenflag == false) {

    videoRef.current.srcObject = screenSharingId;

    Object.values(me?.connections).forEach((connection) => {
          const videoTrack = screenSharingId
              ?.getTracks()
              .find((track) => track.kind === "video");
          connection[0].peerConnection
              .getSenders()
              .find((sender) => sender.track.kind === "video")
              .replaceTrack(videoTrack)
              .catch((err) => console.error(err));
      });

     } else {
       if(stream) {

      videoRef.current.srcObject = stream;

      Object.values(me?.connections).forEach((connection) => {
          const videoTrack = stream
              ?.getTracks()
              .find((track) => track.kind === "video");
          connection[0].peerConnection
              .getSenders()
              .find((sender) => sender.track.kind === "video")
              .replaceTrack(videoTrack)
              .catch((err) => console.error(err));
      });

    }
     }
     },[screenflag])

  useEffect(() =>  {

    if(screenSharingStream)  {
      screenSharingStream.getVideoTracks()[0].addEventListener('ended', () => {
        switchVideoTracks(stream);
        setIsScreenSharingActive(false);
      });
    }

  },[screenSharingStream])

  useEffect(() => {

    if(stream) {

      let recorderStream = new RecordRTCPromisesHandler(stream, {
        type: 'video'
      });

      setRecorder(recorderStream);

    }

  },[stream])


  let dd;

  let tt = "wrapper-container";
  let wbody = "wbody"

  if(Object.values(peers).length == 0) {
    dd = "vd1";
  }

  if(Object.values(peers).length == 1) {
    dd = "vd2";
    tt = "wrapper-container-c2";
    wbody = "wrapper-body-v2";
  }

  if(Object.values(peers).length == 2) {
    dd = "vd3";
    tt = "wrapper-container-c3";
    wbody = "wrapper-body-v3";
  }

  if(Object.values(peers).length == 3) {
    dd = "vd4";
    tt = "wrapper-container-c4";
    wbody = "wrapper-body-v4";

  {/*
    if(chat == true)  {
      wbody = "wrapper-body-v4-nowrap";
    } else {
      wbody = "wrapper-body-v4";
    }
  */}

  }

  if(Object.values(peers).length == 4) {

    dd = "vd5";
    tt = "wrapper-container-c5";
    wbody = "wrapper-body-v5";

    {/*

    if(chat == true)  {
      wbody = "wrapper-body-v5-nowrap";
    } else {
      wbody = "wrapper-body-v5";
    }
    */}

  }

  return (
    <div className="wrapper-room">

            {/* Video Header */}
        <div className="wrapper-title">
        </div>

            {/* Video Body */}
         <div className={wbody}>

           <div className="wrapper-video-all">

            <div className={tt}>

              <video  className={dd}  id="player" ref={videoRef}  autoPlay muted="muted" >
              </video>

              <div className="video-details">

                <div className="video-user-hand">
                {showHandflag ?
                 <div className="video-user-img">
                    <img  src={raisehand} />
                     <span className="video-user-txt">you raise hand</span>
                  </div>
                : <div className="video-user-img-off"> </div> }
                </div>

               <div className="video-user-details">

               <div className="video-user-name">

               </div>


               <div className="video-user-fullscreen" onClick={() => goFullscreen("player")}>
                  <BsFullscreen />
               </div>

               <div className="video-user-name">

               </div>

                <div className="video-user-name">
                   {userdetails.username}
                </div>



                <div className="video-user-line">|</div>

                  { micflag ? null :
                    <div className="video-user-btn">
                      <BsMicMute />
                    </div> }

                  { cameraflag ? null :
                    <div className="video-user-btn">
                     <BsCameraVideoOff />
                   </div> }

               </div>

              </div>


            </div>

            {Object && Object.values(peers).map((peer,index) => {

            return (
              <div key={index} className={tt}>

                <Videoplayer autoPlay={true} str={str}
                   stream={peer.stream}
                   pr={Object.keys(peers)[index]} dd={dd} />

              </div>
              )
            })}

           </div>

            <div className= {chat ? "videoroom-chat" : "videoroom-chat-off"}>
                <Videochat handlechatalert={handlechatalert}
                hideChat={hideChat} chat={chat} />
            </div>

         </div>

            {/* Video footer */}

         <div className="wrapper-footer">
          <div className="video-call-actions">

          {recflag ?
            <button className="video-action-button mic" onClick={videostramflag ? () => startRec() : null}>
              <div className="rec-btn-wrapper"><div className="rec-btn" /></div>
            </button> :
            <button className="video-action-button micoff" onClick={videostramflag ? () => stopRec() : null}>
              <div className="rec-btn-wrapper"><div className="rec-btn" /></div>
            </button>
          }


           {micflag ?
             <button className="video-action-button mic" onClick={videostramflag ? () => stopMic() : null}>
                <img src={mic} />
             </button> :
             <button className="video-action-button micoff" onClick={videostramflag ? () => startMic() : null}>
                <img src={mic} />
             </button>
           }

           {cameraflag ?
             <button className="video-action-button mic" onClick={videostramflag ? () => stopCamera() : null}>
               <img src={video} />
             </button> :
             <button className="video-action-button micoff" onClick={videostramflag ? () => startCamera() : null}>
                <img src={video} />
             </button> }

           {devicetype.device == "desktop" ?
             <>
            {isScreenSharingActive ?
              <button className="video-action-button micoff" onClick={videostramflag ? () => handleScreenShareToggle() : null}>
                <img src={share} />
              </button> :
              <button className="video-action-button mic" onClick={videostramflag ? () => handleScreenShareToggle(): null}>
                <img src={share} />
              </button>
            } </> : null }

           {showHandflag ?
              <button className="video-action-button mic" onClick={videostramflag ? () => showHand() : null}>
                <img src={hand} />
              </button> :
              <button className="video-action-button mic" onClick={videostramflag ? () => showHandOff() : null}>
                <img src={hand} />
              </button>
            }

           {chat ?
              <button className="video-action-button mic" onClick={videostramflag ? () => hideChat() : null}>
                <img src={chaticon} />
              </button> :
              <button className="video-action-button mic" onClick={videostramflag ? () => showChat() : null}>
               <img src={chaticon} />
            </button> }

           {chatalert ?
                <span className="video-chat-alerta">
                  <IoIosAlert/>
                </span> : null }

           {callFlag ?
            <button className="video-action-button-leave leave" onClick={() => stopCall(topicid)}>
              <span>Stop Call</span>
            </button>
             :
             <button className="video-action-button-leave leave" onClick={() => leaveCall()}>
               <span className="licon">
                <img src={call} />
               </span>&nbsp;&nbsp;&nbsp;Leave
             </button>
           }

          </div>
         </div>

    </div>
  );
}

export default Videoroom;

import * as actionTypes from "../actions/actionTypes";

const initialState = {
    chatmsg:[]
};

const chatReducer = (state = initialState, action) => {

  switch (action.type) {

      case actionTypes.ADD_MSG:

       let temp = [];
       temp.push(action.payload);

       return {
         ...state,
          chatmsg:[ ...state.chatmsg, ...temp]
       };

      case actionTypes.ADD_GRP_MSG:

        return {
          ...state,
          chatmsg:[...action.payload]
        };

      case actionTypes.CLEAR_GRP_MSG:

        return {
          chatmsg:initialState
        };

    default:
      return state;
  }
};

export default chatReducer;

import React, { useState,useEffect,useRef }  from 'react';
import * as actionCreators from "../../../redux/actions/actionCreators";

import { IoCloseCircleOutline } from "react-icons/io5";
import { useSelector,useDispatch } from "react-redux";
import { getTopiccategory } from '../../../redux/thunk/topic/fetchdata';

import './Tags.css';

function Tags(props) {

    const dispatch = useDispatch();

    const topiclist = useSelector((state) => state.categoryReducer.topiclist);
    const clearFlag = useSelector((state) => state.categoryReducer.clearFlag);
    const categoryId = useSelector((state) => state.categoryReducer.categoryId);

    const tagref = useRef(null);

    const handleTopic = (e,index,categoryid,topicname) => {

      if(props.name == 'publish') {
        props.selCategory(topicname,categoryid);
        props.hideTag();

      } else {
        dispatch(actionCreators.seltopicTag(index));
        props.handleCloseTag();
        e.preventDefault();
        e.stopPropagation()
      }


    }

    const handleremoveTopic = (e,index) => {
        dispatch(actionCreators.removetopicTag(index));
        e.preventDefault();
        e.stopPropagation()
    }

    const handleClick = (event) => {

      if (tagref.current && !tagref.current.contains(event.target)) {

        if(props.name == 'publish') {
          props.hideTag();
        }  else {
         props.handleCloseTag();
       }

      }

   }

    const handleClearAll = () => {
       const updatedListed = topiclist.map(element => element.selectedFlag === true ? {...element, selectedFlag:false} : element);
       dispatch(actionCreators.resetTopicList(updatedListed));
       dispatch(actionCreators.rmvAllCategory());
   }

    useEffect(() => {
      document.addEventListener("mousedown", handleClick);

      return () => {
         document.removeEventListener("mousedown", handleClick, false);
       };
    },[])

    useEffect(() => {

      const fetchData = async () => {

         if(topiclist == null) {
             const response = await getTopiccategory();
             dispatch(actionCreators.addtopicTag(response.PAYLOAD));
         }

      }

       fetchData();

    },[]);

    useEffect(() => {

      if(topiclist != null) {

        let flagvaluearr = [];

        let flagValue = topiclist.map((element) => {

           if(element.selectedFlag == true) {
             flagvaluearr.push(element.categoryid)
             return element.categoryid;
           } else {
             return null;
           }

        });


        if(flagvaluearr.length > 0) {

            dispatch(actionCreators.addCategory(flagvaluearr));

            if(clearFlag != true) {
               dispatch(actionCreators.setClearFlag(true));
             }

          } else {
              dispatch(actionCreators.rmvAllCategory());
             dispatch(actionCreators.setClearFlag(false));
          }

      } else {
         dispatch(actionCreators.rmvAllCategory());
      }

    },[topiclist])


  return (
      <div className="Tags-wrapper">

         <div ref={tagref} className="Tags-container">

            <div style={{height:10}} />

            <div className="Tags-list">


            <div className="tag-line">
              <div className="tag-head">Topic Category</div>
            <div style={{height:10}} />

          {/*
            {clearFlag ?
              <>
               <div className="Tag-clear" onClick={() => handleClearAll()}>Clear all</div>
               <div style={{height:10}} />
              </>
              :  <>
                 <div className="Tag-clearblank">&nbsp;</div>
                 <div style={{height:10}} />
                </>}
           */}

            </div>

            <div style={{height:30}} />

            {topiclist?.map((element,index) => {

               let usertopic = element.topic.split(" ");

                return  (
                  <div  key={index} className={element.selectedFlag ? "topic-tag-sel" : "topic-tag"}  onClick={(e) => handleTopic(e,index,element.categoryid,element.topic)} >
                    <div className="topicsel1"><IoCloseCircleOutline size={34} color={"black"} /></div>
                     <div className="topicsel">

                        {usertopic.map((ele,ind) => {

                          return (
                            <div key={ind} className={"topseliner"}>&nbsp;{ele}&nbsp;</div>
                          )

                        })}

                      </div>
                     <div   onClick={(e) => handleremoveTopic(e,index)} className={element.selectedFlag ? "topicselicon" : "topicsel1"}>
                       <IoCloseCircleOutline size={34} color={"black"} />
                     </div>
                  </div>
                 );

            })}


            </div>

            <div style={{height:10}} />

         </div>


      </div>
  );
}

export default Tags;

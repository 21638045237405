
import './App.css';
import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useRoutes,useLocation } from 'react-router-dom';
import * as actionCreators from "./redux/actions/actionCreators";
import { usersinfodata } from './redux/thunk/user/fetchdata';
import InitialRoutes from './routes/InitialRoutes';

import { getdeviceType } from './utils/Devicetype';

function App() {

   const dispatch = useDispatch();
   let location = useLocation();
   const authUser = useSelector((state) => state.authReducer.authflag);
   const routing = useRoutes(InitialRoutes(authUser));

   const [localip,setLocalip] = useState(null);
   const [externalip,setExternalip] = useState(null);

   const [infoflag,setInfoflag] = useState(false);


   useEffect(() => {

     let urladdress = location.pathname.substr(0,18);

      if(urladdress === '/pool/pooldetails/') {
        dispatch(actionCreators.setRedirectpath(location.pathname));
      }

   },[])

   useEffect(() => {

    function  determineIPs() {

      const pc = new RTCPeerConnection({ iceServers: [ {urls: 'stun:stun.prioryou.com'} ] });

        pc.createDataChannel('');
        pc.createOffer().then(offer => pc.setLocalDescription(offer))
        pc.onicecandidate = (ice) => {

        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          pc.close();
          return;
        }


       let split = ice.candidate.candidate.split(" ");


         console.log("split",split);

         setLocalip(split[4]);
         setExternalip(split[4]);

    {/*
        if (split[7] === "host") {
          setLocalip(split[4]);
        } else {
          setExternalip(split[4]);
        }

     */}


    };


  }

   determineIPs();

 },[]);


 useEffect(() => {

    if(localip!= null || externalip != null) {
      setInfoflag(true);
    }

 },[localip,externalip])

   useEffect(async () => {

    let browser = navigator.userAgent;
    let gmt = (new Date().toTimeString().slice(9));
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    var currentDate = new Date();

    const deviceType = getdeviceType();
    dispatch(actionCreators.deviceOrinent(deviceType));

    let day = currentDate.getDate();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();

    let date =  day+"/"+"/"+month+"/"+year;

    let hour = currentDate.getHours();
    let min = currentDate.getMinutes();
    let sec = currentDate.getSeconds();

    let time = hour+":"+min+":"+sec;
    let timestamp = currentDate.getTime();


       let obj = {
        localip:localip,
        externalip:externalip,
        browser:browser,
        gmt:gmt,
        timezone:timezone,
        date:date,
        time:time,
        timestamp:timestamp
      }

      console.log("obj",obj);

       let loginobj = {
          userid:"",
          time:time,
          date:date,
          gmt:gmt,
          timezone:timezone,
          localip:localip,
          externalip:externalip,
          browser:browser,
          deviceinfo:deviceType.device,
          os:window.navigator.platform
       }

       console.log("loginobj",loginobj);

       dispatch(actionCreators.adduserInfoData(loginobj));

       const response = await usersinfodata(obj);


   },[infoflag]);


  return (
    <div className="App">
       {routing}
    </div>
  );
}

export default App;

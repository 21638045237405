import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // storage = localStorage or sessionStorage = sessionStorage
import { persistReducer,createTransform  } from 'redux-persist';

import commentReducer from './reducers/commentReducer';
import publishReducer from './reducers/publishReducer';
import userReducer from './reducers/userReducer';
import replyReducer from './reducers/replyReducer';
import poolDetailsReducer from './reducers/poolDetailsReducer';
import notifyReducer from './reducers/notifyReducer';
import popupReducer from './reducers/popupReducer';
import reqnavReducer from './reducers/reqnavReducer';
import refreqReducer from './reducers/refreqReducer';
import reqconfirmReducer from './reducers/reqconfirmReducer';
import activeReducer from './reducers/activeReducer';
import teamReducer from './reducers/teamReducer';
import videoReducer from './reducers/videoReducer';
import tstReducer from './reducers/tstReducer';
import reqgrpcallReducer from './reducers/reqgrpcallReducer';
import deviceReducer from './reducers/deviceReducer';
import chatReducer from './reducers/chatReducer';
import participantReducer from './reducers/participantReducer';
import callReducer from './reducers/callReducer';
import roomReducer from './reducers/roomReducer';
import postlistReducer from './reducers/postlistReducer';
import searchReducer from './reducers/searchReducer';
import searchpeopleReducer from './reducers/searchpeopleReducer';
import otpReducer from './reducers/otpReducer';
import accountDetailReducer from './reducers/accountDetailReducer';
import messageReducer from './reducers/messageReducer';
import resourcesReducer from './reducers/resourcesReducer';
import squadReducer from './reducers/squadReducer';
import welReducer from './reducers/welReducer';
import profileReducer from './reducers/profileReducer';
import takeawayReducer from './reducers/takeawayReducer';
import shareReducer from './reducers/shareReducer';
import deactReducer from './reducers/deactReducer';
import notifyAlertReducer from './reducers/notifyAlertReducer';
import socketReducer from './reducers/socketReducer';
import authReducer from './reducers/authReducer';
import requestdeleteReduce from './reducers/requestdeleteReduce';
import redirectReducer from './reducers/redirectReducer';
import recoverycodeReducer from './reducers/recoverycodeReducer';
import usersinfodataReducer from './reducers/usersinfodataReducer';
import categoryReducer from './reducers/categoryReducer';


const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['resources','comment','teamReducer','deviceReducer','authReducer','activeReducer','usersinfodataReducer']
}

const rootReducer = combineReducers({
	commentReducer:commentReducer,
	publish:publishReducer,
	userReducer:userReducer,
	replyReducer:replyReducer,
	poolDetailsReducer:poolDetailsReducer,
	notifyReducer:notifyReducer,
	popupReducer:popupReducer,
	reqnavReducer:reqnavReducer,
	refreqReducer:refreqReducer,
	reqconfirmReducer:reqconfirmReducer,
	activeReducer:activeReducer,
	teamReducer:teamReducer,
	videoReducer:videoReducer,
	reqgrpcallReducer:reqgrpcallReducer,
	tstReducer:tstReducer,
	deviceReducer:deviceReducer,
	chatReducer:chatReducer,
	participantReducer:participantReducer,
	callReducer:callReducer,
	roomReducer:roomReducer,
	postlistReducer:postlistReducer,
	searchReducer:searchReducer,
	searchpeopleReducer:searchpeopleReducer,
	otpReducer:otpReducer,
	accountDetailReducer:accountDetailReducer,
	messageReducer:messageReducer,
	resourcesReducer:resourcesReducer,
	squadReducer:squadReducer,
	welReducer:welReducer,
	profileReducer:profileReducer,
	takeawayReducer:takeawayReducer,
	shareReducer:shareReducer,
	deactReducer:deactReducer,
	notifyAlertReducer:notifyAlertReducer,
	socketReducer:socketReducer,
	authReducer:authReducer,
	requestdeleteReduce:requestdeleteReduce,
	redirectReducer:redirectReducer,
	recoverycodeReducer:recoverycodeReducer,
	usersinfodataReducer:usersinfodataReducer,
	categoryReducer:categoryReducer
});

export default persistReducer(persistConfig,rootReducer);

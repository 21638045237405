import './Browserpool.css';
import React, { useState,useEffect,useRef,useCallback } from 'react';
import * as actionCreators from "../../../redux/actions/actionCreators";
import { Routes, Link, Route } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { poolExplore } from '../../../redux/thunk/pool/fetchdata';
import { userTopicFilter } from '../../../redux/thunk/topic/fetchdata';

import { IoCloseCircleOutline } from "react-icons/io5";

import pool from '../../../assets/dashboard/rideinpool.png';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';
import Pbimg from '../homepage-maincontainer/Poolrequest/Pbimg';
import Tags from './Tags';

function Browselist() {

  const dispatch = useDispatch();

  const topiclist = useSelector((state) => state.categoryReducer.topiclist);
  const clearFlag = useSelector((state) => state.categoryReducer.clearFlag);
  const categoryId = useSelector((state) => state.categoryReducer.categoryId);


  const [count,setCount] = useState(100);
  const [limit,setlimit] = useState(500);
  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [aa,setAa] = useState([]);
  const [temp,setTemp] = useState();

  const [tagflag,setTagflag] = useState(false);
  const [topicname,setTopicname] = useState([]);
  const [seltopicindex,setSeltopicindex] = useState([]);

  const [filterpage,setFilterpage] = useState(1);
  const [filterhasMore,setfilterHasMore] = useState(true);
  const [filterResult,setFilterResult] = useState([]);
  const [filterList,setFilterList] = useState([]);
  const [filtervalue,setFiltervalue] = useState();

  const [filterFlag,setFilerflag] = useState(false);

  const navigate = useNavigate();
  const observer = useRef();

  const lastBookElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })


  const lastFilterElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && filterhasMore) {
           console.log("Inside hasMore")
          setFilterpage(filtervalue);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleBack = () => {
     navigate('/');
  }

  const handleTag = () => {
    setTagflag(true);
  }

  const handleCloseTag = (topicindex) => {
    setTagflag(false);
  }

  const handleSelectedtag = (topicName,index) => {
     setTopicname([...topicname,topicName])
     setSeltopicindex([...seltopicindex,index])
  }

  const handleRemovetag = (e,index) => {

    dispatch(actionCreators.removetopicTag(index));

    let flagValue = topiclist.filter((element) => {

       if(element.selectedFlag == true) {
         return element.selectedFlag;
       }

    });

    if(flagValue.length > 0) {
     } else {
       dispatch(actionCreators.setClearFlag(false));
    }



    e.preventDefault();
    e.stopPropagation()

  }

  const handleClearAll = () => {
    const updatedListed = topiclist.map(element => element.selectedFlag === true ? {...element, selectedFlag:false} : element);
    dispatch(actionCreators.resetTopicList(updatedListed));
    dispatch(actionCreators.rmvAllCategory());
  }

  const handleClearPrv = () => {
     setfilterHasMore(true);
     setFilterpage(1)
     setFiltervalue()
     setFilterResult([]);
  }

  useEffect(() => {
     if(count == limit) {
          setHasMore(false)
     }
  }, [count]);

  useEffect(async () => {

    let lim = 5;

    const result = await poolExplore(page,lim)

     if(result) {
       let ft = result[0];

      if(ft.next == undefined) {
        setAa([...aa,...result.slice(1)]);
        setHasMore(false);
      } else {
        setAa([...aa,...result.slice(1)]);
        setTemp(ft.next.page)
      }

     }

  }, [page]);

  useEffect(() => {

    if(topiclist != null) {

      let flagvaluearr = [];

      let flagValue = topiclist.map((element) => {

         if(element.selectedFlag == true) {
           flagvaluearr.push(element.categoryid)
           return element.categoryid;
         } else {
           return null;
         }

      });


      if(flagvaluearr.length > 0) {

          dispatch(actionCreators.addCategory(flagvaluearr));

          if(clearFlag != true) {
             dispatch(actionCreators.setClearFlag(true));
           }

        } else {
            dispatch(actionCreators.rmvAllCategory());
           dispatch(actionCreators.setClearFlag(false));
        }

    } else {
       dispatch(actionCreators.rmvAllCategory());
    }

  },[topiclist])

 useEffect(() => {
   setfilterHasMore(true);
   setFilterpage(1)
   setFiltervalue()
   setFilterResult([]);
 },[categoryId])

  useEffect(() => {

    if(categoryId != null) {

    if(filterFlag == false) {
      setFilerflag(true);
    }

      const fetchData = async () => {

         let topicobj = {
           categoryId:categoryId,
           filterpage:filterpage,
           lim:5
         }


         const result = await userTopicFilter(topicobj);

         if(result) {
           let ft = result[0];

          if(ft.next == undefined) {
            setFilterResult([...filterResult,...result.slice(1)]);
            setfilterHasMore(false);
          } else {
            console.log("Inside else");
            console.log("ft.next.page",ft.next.page);
            setFilterResult([...filterResult,...result.slice(1)]);
            setFiltervalue(ft.next.page)
          }

        } else {
          setfilterHasMore(false);
        }


       }
      fetchData();

    }

    if(categoryId == null) {
       setFilerflag(false);
       setFilterList([]);
    }

  },[categoryId,filterpage])

  return (

    <div className="browselist-body">

      <div className="browselist-title">
         <div className="browselist-img">
            <img className="browselist-poolimg" src={pool} />
          </div>

          <div className="call-list-wrapper">
            <h4 className="call-list-title1">Discussion</h4>
            <h4 className="call-list-title1">&</h4>
            <h4 className="call-list-title1">Learning</h4>
         </div>

        <div style={{height:10}} />

         <div className="tag-wrpapper-line">

           <div className="tag-line" onClick={() => handleTag()}>
             <div className="topic-tag-title">t a g s </div>
           </div>

           <div style={{height:10}} />

     {/*
         {clearFlag ?
            <div className="tag-line" onClick={() => handleClearAll()}>
               <div className="Tag-clear">Clear all</div>
               <div style={{height:5}} />
             </div> :
            <div className="tag-line">
              <div className="Tag-clearblank">&nbsp;</div>
            </div>
          }
      */}

          <div style={{height:10}} />

       <div className="tagselect">

       {topiclist?.map((element,index) => {

         return (
           <>
            {element.selectedFlag ?
              <div className="topic-tag-wrapper">
                <div className="topic-tag-wrapper-width" />
                <div>{element.topic} </div>
                <div className="topic-tag-wrapper-width" />
                <div className="topicselicon1" onClick={(e) => handleRemovetag(e,index)}>
                  <IoCloseCircleOutline size={34}  />
                </div>
               </div>
              : null }

              </>
            )

          })}

       </div>


         </div>

      </div>

      {tagflag ?
      <Tags handleCloseTag={handleCloseTag} handleClearPrv={handleClearPrv}  /> : null}

      {filterFlag ?
     <>
       {filterResult && filterResult.map((element,index) => {
        return (
           <div key={index} ref={lastFilterElementRef} className="browselist-wrapper">
          <div>&nbsp;</div>
          <Link className="browselist-link" to={`pooldetails/${element.topicId}`} >
             <div className="browselist-wrapperimg">
              {element.imagename ?
               <img className="browselist-poolscrollimg" src={element.imagename}  />
              : <Pbimg /> }
            </div>

             <div className="browselist-desctable">
                <div className="browselist-topicdetails">
                  <div className="browselist-topic">Topic</div>
                  <div>&nbsp;</div>
                  <div id="ptid" className="browselist-desc">{element.topic}</div>
                </div>

                 <div className="browselist-topicdetails">
                   <div className="browselist-topic">With</div>
                   <div>&nbsp;</div>
                   <div className="browselist-desc">{element.yourname}</div>
                 </div>

                 <div className="browselist-topicdetails">
                   <div className="browselist-topic">Want</div>
                   <div>&nbsp;</div>
                   <div className="browselist-desc">{element.noofpartner} friends</div>
                 </div>

                 <div className="browselist-topicdetails">
                   <div className="browselist-topic">For</div>
                   <div>&nbsp;</div>
                   <div className="browselist-desc">{element.noofdays} days</div>
                 </div>

                 <div className="browselist-topicdetails">
                   <div className="browselist-topic">Start</div>
                   <div>&nbsp;</div>
                   <div className="browselist-desc">{element.startDate}@{element.startTime}</div>
                 </div>

              </div>
            </Link>
           </div>
         )
     })}
     {filterhasMore ?
      <div className="browselist-spinner">
        <Doublebounce />
      </div> : null }
    </>
     :
     <>
       {aa && aa.map((element,index) => {
          return (
             <div key={index} ref={lastBookElementRef} className="browselist-wrapper">
            <div>&nbsp;</div>
            <Link className="browselist-link" to={`pooldetails/${element.topicId}`} >
               <div className="browselist-wrapperimg">
                {element.imagename ?
                 <img className="browselist-poolscrollimg" src={element.imagename}  />
                : <Pbimg /> }
              </div>

               <div className="browselist-desctable">
                  <div className="browselist-topicdetails">
                    <div className="browselist-topic">Topic</div>
                    <div>&nbsp;</div>
                    <div id="ptid" className="browselist-desc">{element.topic}</div>
                  </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">With</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.yourname}</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">Want</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.noofpartner} friends</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">For</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.noofdays} days</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">Start</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.startDate}@{element.startTime}</div>
                   </div>

                </div>
              </Link>
             </div>
           )
       })}
       {hasMore ?
        <div className="browselist-spinner">
          <Doublebounce />
        </div> : null }
     </>
    }

     </div>

  )
}

export default Browselist;

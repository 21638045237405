import React from 'react';
import { useState,useEffect } from 'react';
import { useDispatch,useSelector } from "react-redux";

import './Prequest.css';
import "react-datepicker/dist/react-datepicker.css";

import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiX } from 'react-icons/bi';
import { BiWorld } from 'react-icons/bi';

import { createTimeline,removeFilepath } from '../../../../redux/actions/actionCreators';
import { rangeDate } from '../../../../services/RangeDate';
import { formatDate } from '../../../../utils/Dateformat';
import { returnPhotoURL,fileSize } from '../../../../services/FileType';
import timezonelist from '../../../../json/timezone.json';

import Timepicker from './timepicker';
import Squadpicker from './Squadpicker';
import Duration from './Duration';
import Timezone from './Timezone';
import Datepickercomp from './Datepickercomp';
import DatePicker from "react-datepicker";
import Timepicker1 from './timepicker1';
import Tags from '../../pool/Tags';


function Prequest(props) {

  const dispatch = useDispatch();
  const topiclist = useSelector((state) => state.categoryReducer.topiclist);

  const [file, setFile] = useState();
  const [filepath, setFilepath] = useState("");
  const [imageFlag,setimageFlag] = useState(true);
  const [imageError,setImageError] = useState(false);
  const [imageErrormsg,setImageErrormsg] = useState("");

  const [yourname,setYourname] = useState("");
  const [topic,setTopic] = useState("");
  const [desc,setDesc] = useState("");

  const [yournameflag,setYournameflag] = useState(false);
  const [topicflag,setTopicflag] = useState(false);

  const [showsduration,setDuration] = useState(false);
  const [showCategory,setCategory] = useState(false);
  const [nameCategory,setnameCategory] = useState(null);
  const [categoryId,setcategoryId] = useState(null);
  const [nameCategoryflag,setNameCategoryflag] = useState(false);


  const [numberDays,setNumberDays] = useState(null);
  const [durationflag,setDurationflag] = useState(false);

  const [starttime,setStarttime] = useState("");

  const [publishdate,setPublishDate] = useState("");
  const [currentDate,setCurrentDate] = useState("");
  const [datepickerflag,setDatepicker] = useState(false);
  const [dateflag,setDateflag] = useState(false);

  const [numberPartner,setnumberPartner] = useState(null);
  const [showsquad,setShowsquad] = useState(false);
  const [numberText,setnumberText] = useState("");
  const [squadflag,setSquadflag] = useState(false);

  const [timezoneFlag,setTimezoneflag] = useState(false);
  const [timezone,setTimezone] = useState(null);
  const [gmtoffset,setGmtoffset] = useState("");

  const [submitflag,setSubmitflag] = useState(false);


  const showduration = () => {
    setDuration(true);
  }

  const selCategory = (topicname,categoryid) => {
    setnameCategory(topicname)
    setcategoryId(categoryid);
  }

  const showTag = () => {
    setCategory(true);
  }

  const hideTag = () => {
    setCategory(false);
  }

  const hideDuration = () => {
    setDuration(false);
  }

  const showTimezone = () =>  {
    setTimezoneflag(true);
  }

  const hideTimezone = ()  => {
     setTimezoneflag(false);
  }

  const mountTimezone = (value,gmtoffset) => {
    setTimezone(value);
    setTimezoneflag(false);
    setGmtoffset(gmtoffset);
  }

  const showDatepicker = () => {
    setDatepicker(true);
  }

  const hideDatepicker = () => {
    setDatepicker(false);
  }

  const mountDate = (newDate) => {

    let date = newDate;
    let t = date.toDateString().split(" ");
    let temp = t[2] + " " + t[1] + " " + t[3] + " " + "( "+t[0]+" )" ;

    setCurrentDate(temp);
    setDatepicker(false);

    let resdate = formatDate(temp);
    setPublishDate(resdate);

  }

  const handleSquad = () => {
    setShowsquad(true);
  }

  const squadSelect = (squadnumber,str) => {
      setnumberPartner(squadnumber);
      setShowsquad(false);
      setnumberText(str)
  }

  const hideSquad = () => {
    setShowsquad(false);
  }

  const onImageUpload = async (event) => {

  let size = await fileSize(event.target.files[0].size);

   // Check file size
   if(size === true) {
       setImageErrormsg("File too Big,Select a file less than 4mb");
       setImageError(true);
       return;
   }

   if(size === false) {

     let result = await returnPhotoURL(URL.createObjectURL(event.target.files[0]));

      // Check image is valid or not
      if(result === true) {
          setimageFlag(true);
          setImageError(true);
          setImageErrormsg("Image is not valid format");
          return;
      }

      if(result === false) {
           setFilepath(event.target.files[0]);
           setFile(URL.createObjectURL(event.target.files[0]));
           setimageFlag(false);
           setImageError(false);
       }
    }

}

  const handleDescription = (event) => {
     setDesc(event.target.value)
  }

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
     e.preventDefault();
  }

  const dragLeave = (e) => {
     e.preventDefault();
  }

  const fileDrop = async(event) => {

    event.preventDefault();
    event.stopPropagation();

    let size = await fileSize(event.dataTransfer.files[0].size);

    var binaryData = [];
    binaryData.push(event.dataTransfer.files[0]);

    if(size === true) {
      setImageErrormsg("File too Big,Select a file less than 4mb");
      setImageError(true);
      setimageFlag(true);
      return;
    }

    if(size === false) {

       let result = await returnPhotoURL(URL.createObjectURL(binaryData[0]));


        if(result === true) {
            setimageFlag(true);
            setImageError(true);
            setImageErrormsg("Image is not valid format");
            return;
         }

        if(result === false) {
             setFilepath(binaryData[0]);
             setFile(URL.createObjectURL(binaryData[0]));
             setimageFlag(false);
             setImageError(false);
         }
     }

 }

  const handleImgcancel = () => {

    setimageFlag(true);
    setImageError(false);
    setFilepath("");

    let result = rangeDate(publishdate,numberDays);

    let timeline = result.map((element,index) => {
         return {
           date:element.toLocaleDateString('en-GB', {
              day: '2-digit', month: 'short', year: 'numeric'
              }).replace(/ /g, '-'),
           title:"",
           description:""
         }
    });

    let payload = {
      yourname:yourname,
      topic:topic,
      description:desc,
      noofpartner:numberPartner,
      noofdays:numberDays,
      startdate:publishdate,
      starttime:starttime,
      filepath:"",
      timeline:timeline
    }

    dispatch(removeFilepath(payload));
  }

  const handleName = (event) => {
    setYourname(event.target.value);
  }

  const handleTopic = (event) => {
    setTopic(event.target.value);
  }

  const handleStarttime = (hour,minute,meridiem) => {
      let time = hour + ":" + minute + " " + meridiem;
      setStarttime(time);
  }

  const handlePatnercnt = (event) => {
     setnumberPartner(event.target.value);
  }

  const handleDaycnt = (value) => {
    setNumberDays(value);
  }

  const handleTimeline = () => {

    if (yourname === "") {
        setYournameflag(true);
        window.scrollTo(0, 0);
    } else {
        setYournameflag(false);
    }

    if (topic === "") {
        setTopicflag(true);
        window.scrollTo(0, 0);
    } else {
        setTopicflag(false);
    }

    if(nameCategory == null) {
       setNameCategoryflag(true);
       window.scrollTo(0, 0);
    } else {
       setNameCategoryflag(false);
    }

    if (numberPartner == null) {
        setSquadflag(true);
    } else {
        setSquadflag(false);
    }

    if (publishdate === "") {
        setDateflag(true);
     } else {
        setDateflag(false);
    }

    if (numberDays === null) {
        setDurationflag(true);
    } else {
        setDurationflag(false);
    }

     if (yourname !== "" &&  topic !== "" &&  publishdate !== "" && numberPartner !== null && numberDays !== null && nameCategory !== null) {

         let result = rangeDate(publishdate,numberDays);

         let timeline = result.map((element,index) => {
              return {
                date:element.toLocaleDateString('en-GB', {
                   day: '2-digit', month: 'short', year: 'numeric'
                   }).replace(/ /g, '-'),
                title:"",
                description:""
              }
         });

         let payload = {
           yourname:yourname,
           topic:topic,
           description:desc,
           filepath:filepath,
           noofpartner:numberPartner,
           noofdays:numberDays,
           startdate:publishdate,
           timezone:timezone,
           starttime:starttime,
           timeline:timeline,
           gmtoffset:gmtoffset,
           topic_categoryid:categoryId,
           topic_categoryname:nameCategory
         }


         dispatch(createTimeline(payload));
         props.navTimeline(publishdate,numberDays,topic);
     } else {
        setSubmitflag(true);
     }

  }

    useEffect(() => {

      window.scrollTo(0, 0);

      if(filepath) {
       setFile(URL.createObjectURL(filepath));
       setimageFlag(false);
       setImageError(false);
     }

    },[filepath])

    useEffect(() => {

      let date = new Date();

      let ntemp = date.setDate(date.getDate() + 1);
      let ndate = new Date(ntemp);

      let t = ndate.toDateString().split(" ");
      let temp = t[2] + " " + t[1] + " " + t[3] + " " + "( "+t[0]+" )" ;

      setCurrentDate(temp);

      let resdate = formatDate(temp);
      setPublishDate(resdate);

     if(timezonelist.length > 0) {

       let value = Intl.DateTimeFormat().resolvedOptions().timeZone;

       if(value === "Asia/Calcutta" || value === "Asia/Kolkata") {

        let finalresult = "India Asia/Mumbai - UTC +05:30";

        setTimezone(finalresult);
        setGmtoffset("UTC +05:30");

       } else {

         let filtervalue = timezonelist.filter((item) =>  {
            return (
              item.TimeZone.toLowerCase().includes(value.toLowerCase())
           );
         });


       if(filtervalue.length > 0) {

           let finalresult = "";

           if(filtervalue[0].TimeZone) {
              finalresult = `${filtervalue[0].CountryName} ${filtervalue[0].TimeZone} - ${filtervalue[0].GMTOffset}`;
           }

           setTimezone(finalresult);
           setGmtoffset(filtervalue[0].GMTOffset);

       } else {

          let finalresult = "India Asia/Mumbai - UTC +05:30";

          setTimezone(finalresult);
          setGmtoffset("UTC +05:30");
       }

     }

    }

   },[])

  return (

     <div className="prequest-wrapper">

           {/* Pool request title */}

          <h2 className="prequest-title">
            Publish In Pool
          </h2>

           {/* Pool request body */}


        <div className="prequest-body">

            {/* Your Name */}

           <input  type="text" className="prequest-formcontrol" placeholder="Your Name" maxLength="30" onChange={handleName}  value={yourname} />
           <p className="prequest-errmsg">{yournameflag ? "* Yourname is required" : null}</p>
           <div className="prequest-space" />

            {/* Topic */}

           <input type="text" className="prequest-formcontrol" placeholder="Topic" maxLength="38" onChange={handleTopic} value={topic} />
           <p className="prequest-errmsg">{topicflag ? "* Topic is required" : null}</p>
           <div className="prequest-space" />

            {/* Description */}

           <textarea placeholder="Add Description" maxLength="500" className="prequest-textarea" onChange={handleDescription} value={desc} />
           <div className="prequest-space" />

            {/* Image Drag and Drop */}

            {imageFlag ?
              <div className="prequest-upload" onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop} >

               <label className="prequest-uploadlbl">

                <div className="prequest-circle">
                  <div className="prequest-arrow"><AiOutlineCloudUpload/></div>
                </div>
                 <div className="prequest-uploadimg">Upload Image</div>

                 <input className="prequest-upbtn" type="file" onChange={onImageUpload} />
                 <div>{imageError ? <p className="prequest-err">{imageErrormsg}</p> : ""}</div>
               </label>
             </div>
             :
             <div className="prequest-uploadimg" onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop} >

                <label className="prequest-uploadlblimg">
                <img  className="prequest-img" src={file} alt="file" />
               </label>
              <BiX className="prequest-cancelicon" onClick={handleImgcancel} />
            </div>
         }

            <div className="prequest-space" />

            {/*Poolrequest no. of patner */}

            <div className="prequest-startlbl">
               <label>Category</label>
            </div>

            <div className="prequest-nodays">

               <div className="prequest-patnerlbl" onClick={() => showTag()}>
                 <div>&nbsp;{nameCategory ? `${nameCategory}` : "Select Category" }&nbsp;</div>
               </div>

                <div className="prequest-patnercnt" >
                  {showCategory ?
                    <Tags hideTag={hideTag} selCategory={selCategory} name={'publish'} />
                  : null }
                </div>

                 <p className="prequest-errdate">{nameCategoryflag ? "* Category is required" : "" }</p>

            </div>

            <div className="prequest-startlbl"><label>Squad</label></div>
              <div className="prequest-patner">

              <div  className="prequest-patnerlbl" onClick={() => handleSquad()}>
                <div>{numberPartner != null ? `Numer of Friends : ${numberText}`  :"Pick Your Squad"}</div>
              </div>



            {showsquad ?
                  <Squadpicker hideSquad={hideSquad} squadSelect={squadSelect} />
             : null }

              <div className="prequest-errdate">{squadflag ? "* Squad is required" : "" }</div>

            </div>

           <div className="prequest-space" />

            {/*Poolrequest no of days */}

            <div className="prequest-startlbl">
               <label>Duration</label>
            </div>

           <div className="prequest-nodays">

              <div className="prequest-patnerlbl" onClick={() => showduration()}>
                <div>{numberDays ? `Duration : ${numberDays} days` : "Select Duration" }</div>
              </div>

               <div className="prequest-patnercnt" >
                 {showsduration ?
                   <Duration hideDuration={hideDuration} handleDaycnt={handleDaycnt} />
                 : null }
               </div>

                <p className="prequest-errdate">{durationflag ? "* Duration is required" : "" }</p>

           </div>

            {/*  Poolrequest date */}

           <div className="prequest-patner">

              <div className="prequest-startlbl">
                <label>Select Start Date</label>
              </div>

              <div>

               {datepickerflag ?
                 <Datepickercomp  mountDate={mountDate} hideDatepicker={hideDatepicker} />
               : <div style={{height:18}}> </div> }

                 <div className="prequest-patnerlbl" onClick={() => showDatepicker()}>
                    <div>{currentDate}</div>
                </div>

             </div>
                 <p className="prequest-errdate">{dateflag ? "* Date is required" : "" }</p>
           </div>

            {/*  Pool timezone */}

            <div className="prequest-startlbl">
               <label>Timezone</label>
            </div>

           <div className="prequest-time">
            <div className="prequest-timetitle-zone" onClick={showTimezone}>
               <div className="prequest-zone"><BiWorld /></div>
                <div className="prequest-zone">{timezone ? timezone :"Timezone"}</div>
            </div>
           {timezoneFlag ? <Timezone mountTimezone={mountTimezone} hideTimezone={hideTimezone} /> : null }
           </div>

           <div className="prequest-space" />

                {/* Timepicker */}

             <div className="prequest-time">
                <div className="prequest-startlbl">
                  <label>Start Time</label>
                 </div>
                <div className="prequest-timeinp">
                  <Timepicker handleStarttime={handleStarttime}  />
                </div>
             </div>

           </div>

          <div className="prequest-space" />

          {/* Timeline Post */}

          <div className="prequest-timeline" onClick={() => handleTimeline()}>
              &nbsp;Create Timeline&nbsp;
          </div>

           <div style={{height:10}}> </div>

          <div className="prequest-errdate">
            {submitflag ? "* Please fill in all required fields" : null}
          </div>

          <div className="prequest-space" />
          <div className="prequest-space" />
          <div className="prequest-space" />
          <div className="prequest-space" />
          <div className="prequest-space" />
          <div className="prequest-space" />

     </div>

 )

}

export default Prequest;

import * as actionTypes from './actionTypes';

// Comment

export const addComment = (payload) => {

  return {
    type:actionTypes.ADD_COMMENT,
    payload:payload
  };
}

export const likeComment = (payload) => {
  return {
    type:actionTypes.LIKE_COMMENT,
    payload:payload
  };
}

export const unlikeComment = (payload) => {
   return {
     type:actionTypes.UNLIKE_COMMENT,
     payload:payload
   }
}

export const rpylikeComment = (payload) => {
  return {
    type:actionTypes.LIKE_REPLY,
    payload:payload
  };
}

export const rpyunlikeComment = (payload) =>  {
  return {
    type:actionTypes.UNLIKE_REPLY,
    payload:payload
  };
}

export const editsaveComment = (payload) => {
  return {
     type:actionTypes.EDIT_SAVE_COMMENT,
     payload:payload
  };
}

export const editsaveReply = (payload) => {
  return {
     type:actionTypes.EDIT_SAVE_REPLY,
     payload:payload
  };
}

export const delreplyComment = (payload) => {
  return {
     type:actionTypes.DELETE_REPLY,
     payload:payload
  };
}

export const delComment = (payload) => {
  return {
     type:actionTypes.DELETE_COMMENT,
     payload:payload
  };
}

export const resetComment = () => {
   return {
     type:actionTypes.RESET_COMMENT
   }
}

export const addPostComment = (payload) => {
   return {
     type:actionTypes.ADD_POST_COMMENT,
      payload:payload
   }
}

// Publish reducer

export const createTimeline = (payload) => {
    return {
      type:actionTypes.CREATE_TIMELINE,
      payload:payload
   };
}

export const editTimeline = (payload) => {
   return {
     type:actionTypes.EDIT_TIMELINE,
     payload:payload
  };
}

export const saveTimeline = (payload) => {
   return {
     type:actionTypes.SAVE_TIMELINE,
     payload:payload
  };
}

export const dateRange = (payload) => {
  return {
    type:actionTypes.DATE_RANGE,
    payload:payload
 };
}

export const removeFilepath = (payload) => {
  return {
    type:actionTypes.REMOVE_FILEPATH,
    payload:payload
 };
}

export const resetPublishform = () => {
  return {
    type:actionTypes.RESET_PUBLISH
 };
}

// Add User Reply Comment

export const addReplyComment = (payload) => {
  return {
    type:actionTypes.ADD_REPLY_COMMENT,
    payload:payload
 };
}

export const addSubReply = (payload) =>  {
   return {
     type:actionTypes.ADD_SUB_REPLY,
     payload:payload
   }
}

// Add User Details

export const addUserDetails = (payload) => {
  return {
    type:actionTypes.ADD_USER_DETAILS,
    payload:payload
 };
}

// Get Topic Details

export const setTopicDetails = (payload) => {
  return {
    type:actionTypes.SET_TOPIC_DETAILS,
    payload:payload
 };
}


// Add Notify Id

export const addNotifyId = (payload) => {
  return {
    type:actionTypes.ADD_NOTIFY_ID,
    payload:payload
 };
}

// ADD Request Delete

export const reqDel = (payload) => {
  return {
    type:actionTypes.REQ_DEL,
    payload:payload
 };
}

// Add Topic id for Navigation of request

export const reqNav = (payload) => {
  return {
    type:actionTypes.REQNAV,
    payload:payload
 };
}

// Refresh User Request

export const refreshReq = (payload) => {
  return {
    type:actionTypes.REFRESHREQ,
    payload:payload
 };
}

// Add Confirm Request

export const confirmReq = (payload) => {
   return {
     type:actionTypes.REQCONFIRM,
     payload:payload
  };
}

// Add Topic Id

export const addTopicId = (payload) => {
   return {
     type:actionTypes.ADD_TOPIC_ID,
     payload:payload
   }
}


export const setTeam = (payload) => {
   return {
     type:actionTypes.SET_TEAM,
     payload:payload
   }
}

export const addMember = (payload) => {
   return {
     type:actionTypes.ADD_MEMBER,
     payload:payload
   }
}

export const rmvMember = (payload) => {
   return {
     type:actionTypes.REMOVE_MEMBER,
     payload:payload
   }
}

export const setFlag = (payload) => {
   return {
     type:actionTypes.SET_FLAG,
     payload:payload
   }
}

export const startCall = (payload) => {
   return {
     type:actionTypes.START_CALL,
     payload:payload
   }
}

export const reqgrpCall = (payload) => {
  return {
    type:actionTypes.REQ_GRP_CALL,
    payload:payload
  }
}

export const deviceOrinent = (payload) => {
  return {
    type:actionTypes.DEVICE_TYPE,
    payload:payload
  }
}

export const tstTopic = (payload) => {
  return {
    type:actionTypes.ADD_TOPIC_ID2,
    payload:payload
  }
}

export const addChatmsg = (payload) => {
  return {
    type:actionTypes.ADD_MSG,
    payload:payload
  }
}

export const addGrpmsg = (payload) => {
  return {
    type:actionTypes.ADD_GRP_MSG,
    payload:payload
  }
}

export const clearGrpmsg = (payload) => {

  return {
    type:actionTypes.CLEAR_GRP_MSG,
    payload:payload
  }
}

export const addParticipant = (payload) => {
  return {
    type:actionTypes.ADD_PARTICIPANT,
    payload:payload
  }
}

export const rmvParticipant = (payload) => {
  return {
    type:actionTypes.REMOVE_PARTICIPANT,
    payload:payload
  }
}

export const callStart = (payload) => {
  return {
    type:actionTypes.CALL_START,
    payload:payload
  }
}

export const callCancel = (payload) => {
  return {
    type:actionTypes.CALL_CANCEL,
    payload:payload
  }
}

export const changeRoomFlag = (payload) => {
  return {
    type:actionTypes.CHANGE_ROOMFLAG,
    payload:payload
  }
}

export const addSmartTag = (payload) => {
  return {
    type:actionTypes.ADD_SMART_TAG,
    payload:payload
  }
}

export const rmvSmarttag = (payload) => {
  return {
    type:actionTypes.DEL_SMART_TAG,
    payload:payload
  }
}

export const addUserPost = (payload) => {
  return {
    type:actionTypes.ADD_USER_POST,
    payload:payload
  }
}

export const addImageUrl = (payload) => {
   return {
     type:actionTypes.ADD_IMAGE_URL,
     payload:payload
   }
}

export const addImageFlag = (payload) => {
   return {
     type:actionTypes.ADD_IMAGE_FLAG,
     payload:payload
   }
}

export const addUserPostdb = (payload) => {
   return {
     type:actionTypes.ADD_USER_POST_DB,
     payload:payload
   }
}

export const addPostpage = (payload) => {
   return {
     type:actionTypes.ADD_POST_PAGE,
     payload:payload
   }
}

export const addPosttemp = (payload) => {
   return {
     type:actionTypes.ADD_POST_TEMP,
     payload:payload
   }
}

export const addPosthasmore = (payload) => {

   return {
     type:actionTypes.ADD_POST_HASMORE,
     payload:payload
   }
}

export const delUserpost = (payload) => {
   return {
     type:actionTypes.DEL_USER_POST,
     payload:payload
   }
}

export const delUserpostlist = (payload) => {
   return {
     type:actionTypes.DEL_USER_POST_LIST,
     payload:payload
   }
}

export const updateUserpost = (payload) => {
  return {
    type:actionTypes.UPDATE_SMART_TAG,
    payload:payload
  }
}

export const updateSmartTagUser = (payload) => {
  return {
    type:actionTypes.UPDATE_SMART_TAG_USR,
    payload:payload
  }
}

export const addUserpreviewid = (payload) => {
  return {
    type:actionTypes.ADD_PREVIEWID,
    payload:payload
  }
}

export const addUserpreviewpost = (payload) => {
  return {
    type:actionTypes.ADD_PREVIEWPOST,
    payload:payload
  }
}

export const rmvUserpreviewpost = (payload) => {
  return {
    type:actionTypes.RMV_USERPOST,
    payload:payload
  }
}

export const setPostCount = (payload) => {
  return {
    type:actionTypes.SET_POST_COUNT,
    payload:payload
  }
}

export const addPostCount = (payload) => {

  return {
    type:actionTypes.ADD_POST_COUNT,
    payload:payload
  }
}

export const restUserPost = (payload) => {
  return {
    type:actionTypes.REST_USER_POST,
    payload:payload
  }
}

export const setscrollPosition = (payload) => {
    return {
      type:actionTypes.SET_SCROLL_POSITION,
      payload:payload
    }
}

export const setPostSearchResult = (payload) => {
   return {
     type:actionTypes.SET_POST_SEARCH,
     payload:payload
   }
}

export const setPostSearchTerm = (payload) => {
   return {
     type:actionTypes.SET_POST_SEARCH_TERM,
     payload:payload
   }
}

export const clearPostSearchTerm = (payload) => {
   return {
     type:actionTypes.CLEAR_POST_SEARCH,
     payload:payload
   }
}

export const addSearchpage = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_PAGE,
     payload:payload
   }
}

export const addSearchtemp = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_TEMP,
     payload:payload
   }
}

export const addSearchhasmore = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_HASMORE,
     payload:payload
   }
}

export const addSearchTerm = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_TERM,
     payload:payload
   }
}



export const addSearchPoolTerm = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_POOL_TERM,
     payload:payload
   }
}

export const addSearchPoolHasmore = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_POOL_HASMORE,
     payload:payload
   }
}

export const addSearchPoolTemp = (payload) => {
   return {
     type:actionTypes.ADD_SEARCH_POOL_TEMP,
     payload:payload
   }
}

export const clearSearchPool = (payload) => {
  return {
    type:actionTypes.CLEAR_SEARCH_POOL,
    payload:payload
  }
}

export const addSearchPoolPage = (payload) => {
  return {
    type:actionTypes.ADD_SEARCH_POOL_PAGE,
    payload:payload
  }
}

export const addSearchPool = (payload) => {
  return {
    type:actionTypes.ADD_SEARCH_POOL,
    payload:payload
  }
}



export const addPeoplePoolTerm = (payload) => {
  return {
    type:actionTypes.ADD_PEOPLE_TERM,
    payload:payload
  }
}

export const addPeoplePoolHasmore = (payload) => {
  return {
    type:actionTypes.ADD_SEARCH_PEOPLE_HASMORE,
    payload:payload
  }
}

export const addPeoplePoolTemp = (payload) => {
  return {
    type:actionTypes.ADD_SEARCH_PEOPLE_TEMP,
    payload:payload
  }
}

export const addPeoplePoolPage = (payload) => {
  return {
    type:actionTypes.ADD_SEARCH_PEOPLE_PAGE,
    payload:payload
  }
}

export const addPeoplePool = (payload) => {
  return {
    type:actionTypes.ADD_PEOPLE_POOL,
    payload:payload
  }
}


// OTP

export const setOtpFlag = (payload) => {

  return {
    type:actionTypes.SET_OTP_FLAG,
    payload:payload
  }
}

export const updateUserEmail = (payload) => {
  return {
    type:actionTypes.UPDATE_EMAIL_ID,
    payload:payload
  }
}

export const updateUserName = (payload) => {
  return {
    type:actionTypes.UPDATE_USERNAME,
    payload:payload
  }
}

export const addUserAccountDetails = (payload) => {
   return {
     type:actionTypes.ADD_USER_ACCOUNT_DETAIL,
     payload:payload
   }
}

export const addChatUserList = (payload) => {
   return {
     type:actionTypes.ADD_CHAT_USER_LIST,
     payload:payload
   }
}

export const addCurrentChatUser = (payload) => {
   return {
     type:actionTypes.ADD_CURRENT_CHAT_USER,
     payload:payload
   }
}

export const addUserChat = (payload) => {
   return {
     type:actionTypes.ADD_USER_CHAT,
     payload:payload
   }
}

export const addNewUserChat = (payload) => {
  return {
      type:actionTypes.ADD_NEW_USER_CHAT,
      payload:payload
  }
}

export const delUserChatmsg = (payload) => {
   return {
     type:actionTypes.DEL_CHAT_MSG,
     payload:payload
   }
}

export const updatChatmsg = (payload) => {
  return {
    type:actionTypes.UPDATE_CHAT_MSG,
    payload:payload
  }
}

export const updateAllchatmsg = (payload) => {
  return {
    type:actionTypes.UPDATE_ALL_CHAT_MSG,
    payload:payload
  }
}

export const delAllChatMsg = (payload) => {
  return {
    type:actionTypes.DEL_ALL_CHAT_MSG,
    payload:payload
  }
}

export const updateChatUserList = (payload) => {
  return {
    type:actionTypes.UPDATE_CHAT_USER_LIST,
    payload:payload
  }
}

//  Resources

export const addResources = (payload) => {
  return {
    type:actionTypes.ADD_RESOURCES,
    payload:payload
  }
}

export const delResources = (payload) => {
  return {
    type:actionTypes.DEL_RESOURCES,
    payload:payload
  }
}

export const updateResources = (payload) => {
  return {
    type:actionTypes.UPDATE_RESOURCES,
    payload:payload
  }
}

export const updateAddResources = (payload) => {
  return {
    type:actionTypes.UPDATE_ADD_RESOURCES,
    payload:payload
  }
}

export const addAllResources = (payload) => {
  return {
    type:actionTypes.ADD_ALL_RESOURCES,
    payload:payload
  }
}

export const updateResourcesRecord = (payload) => {
  return {
    type:actionTypes.UPDATE_RESOURCES_RECORD,
    payload:payload
  }
}

export const updateDelResources = (payload) => {
  return {
    type:actionTypes.UPDATE_DEL_RECORD,
    payload:payload
  }
}

// SQUAD

export const leavefromSquad = (payload) => {
  return {
    type:actionTypes.LEAVE_SQUAD,
    payload:payload
  }
}

export const removefromSquad = (payload) => {
  return {
    type:actionTypes.REMOVE_SQUAD,
    payload:payload
  }
}

export const reportSquadfor = (payload) => {
  return {
    type:actionTypes.REPORT_SQUAD,
    payload:payload
  }
}

export const delTeamMember = (payload) => {
  return {
    type:actionTypes.DEL_TEAM_MEMBER,
    payload:payload
  }
}

export const setWelcomeFlag = (payload) => {
  return {
    type:actionTypes.WELCOME_FLAG,
    payload:payload
  }
}

export const setSterotypeFlag = (payload) => {
  return {
    type:actionTypes.STEROTYPE_FLAG,
    payload:payload
  }
}

export const setSterotype = (payload) => {
  return {
    type:actionTypes.SET_STEROTYPE,
    payload:payload
  }
}

export const postResetInitialState = () => {
  return {
    type:actionTypes.POST_RESET_INITIAL_STATE,
  }
}

export const showTakeAway = (payload) => {
  return {
    type:actionTypes.SHOW_TAKEAWAY,
    payload:payload
  }
}

export const showShareBox = (payload) => {
  return {
    type:actionTypes.SHOW_SHARE_BOX,
    payload:payload
  }
}

export const resetReply = () => {
   return {
     type:actionTypes.RESET_REPLY
   }
}

export const rmvReplyCommentId = (payload) => {
  return {
    type:actionTypes.RMV_REPLY_COMMENT_ID,
    payload:payload
  }
}


export const addCountComment = (payload) => {
  return {
    type:actionTypes.ADD_COUNT_COMMENT,
    payload:payload
  }
}

export const decAccFlag = (payload) => {
  return {
    type:actionTypes.DEACT_FLAG,
    payload:payload
  }
}

export const decAccforuser = (payload) => {
  return {
    type:actionTypes.DEACT_ACCN_FLAG,
    payload:payload
  }
}

export const addnotifyAlert = (payload) => {
  return {
    type:actionTypes.ADD_NOTIFY_ALERT,
    payload:payload
  }
}

export const addmsgAlert = (payload) => {
  return {
    type:actionTypes.ADD_MSG_ALERT,
    payload:payload
  }
}

export const addRoomDetails = (payload) => {
  return {
    type:actionTypes.ADD_ROOM_DETAILS,
    payload:payload
  }
}

// User Authentication

export const addAuthUser =  (payload) => {
  return {
    type:actionTypes.ADD_AUTH_USER,
    payload:payload
  }
}


export const addRequestdeleteId =  (payload) => {
  return {
    type:actionTypes.ADD_REQUEST_DELETE_ID,
    payload:payload
  }
}

export const restUserPool =  (payload) => {
  return {
    type:actionTypes.REST_PEOPLE_POOL,
    payload:payload
  }
}

export const updateStatus = (payload) =>  {
  return {
    type:actionTypes.UPDATE_USER_STATUS,
    payload:payload
  }
}


export const updateCancelStatus = (payload) =>  {
  return {
    type:actionTypes.UPDATE_USER_CANCEL_STATUS,
    payload:payload
  }
}


export const setRedirectpath = (payload) =>  {
  return {
    type:actionTypes.SETREDIRECTPATH,
    payload:payload
  }
}

export const setrecoveryCode = (payload) => {
  return {
    type:actionTypes.UPDATE_RECOVERYCODE_FLAG,
    payload:payload
  }
}

export const updatEmailVerfication = (payload) => {
    return {
      type:actionTypes.UPDATE_EMAIL_VERFICATION,
      payload:payload
    }
}

export const adduserInfoData = (payload) => {
    return {
      type:actionTypes.ADD_USER_INFODATA,
      payload:payload
    }
}


export const addtopicTag = (payload) => {
    return {
      type:actionTypes.ADD_TAG,
      payload:payload
    }
}


export const seltopicTag = (payload) => {
    return {
      type:actionTypes.SELECT_TAG,
      payload:payload
    }
}


export const removetopicTag = (payload) => {
    return {
      type:actionTypes.REMOVE_TAG,
      payload:payload
    }
}

export const setClearFlag = (payload) => {
    return {
      type:actionTypes.CLEAR_FLAG,
      payload:payload
    }
}

export const resetTopicList = (payload) => {
    return {
      type:actionTypes.REST_ALL,
      payload:payload
    }
}


export const addCategory = (payload) => {
  return {
    type:actionTypes.ADD_CATEGORYID,
    payload:payload
  }
}


export const rmvAllCategory = (payload) => {
  return {
    type:actionTypes.REMOVE_ALL_CATEGORYID,
    payload:payload
  }
}

import React, { useState,useEffect,useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { resetPublishform } from '../../../../redux/actions/actionCreators';
// import { HiOutlineEmojiSad } from 'react-icons/hi';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { publishTopic } from '../../../../redux/thunk/publish/fetchdata';
import { handleCompress } from '../../../../services/CompressImage';
import { changeTimeZone } from '../../../../utils/Dateformat';
import Pbimg from './Pbimg';
import Pbsuccess from './Pbsuccess';
import Prioryou from '../../../../assets/default/prioryou.png';
import './Publisherr.css';
import { SocketContext } from "../../../../context/SocketContext";
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

 function Publisherr(props) {

   const navigate = useNavigate();
   const dispatch = useDispatch();
   const publish = useSelector((state) => state.publish);
   const { socket } = useContext(SocketContext);

   const userdetails = useSelector((state) => state.userReducer.userdetails);

   const [fileFlag,setfileFlag] = useState(false);
   const [yourname,setYourname] = useState("");
   const [topic,setTopic] = useState("");
   const [description,setDescription] = useState("");
   const [noofpartner,setNoofpartner] = useState("");
   const [noofdays,setNoofdays] = useState("");
   const [startdate,setStartdate] = useState("");
   const [starttime,setStarttime] = useState("");
   const [timeline,setTimeline] = useState("");
   const [file, setFile] = useState();
   const [timezone,setTimezone] = useState();
   const [doneflag,setDoneflag] = useState(true);
   const [gmtoffset,setGmtoffset] = useState("");
   const [pubFlag,setpubFlag] = useState(false);

   const [categoryId,setCategoryId] = useState("");
   const [nameCategory,setNameCategory] = useState("");

   const done = () => {
     dispatch(resetPublishform());
     navigate("/homepage/profile/yourpool/");
   }

    const handlePublish = async () => {

        setpubFlag(true);

        let indx = timeline.length - 1;

        let edate =  timeline[indx].date + " " + starttime +" "+ gmtoffset;
        let enddateutc = changeTimeZone(new Date(edate), 'Asia/Kolkata');
        let sdate = startdate + " " + starttime +" "+ gmtoffset;
        let sdateutc = changeTimeZone(new Date(sdate), 'Asia/Kolkata');

        let hours = sdateutc.getHours().toString();
        let min = sdateutc.getMinutes().toString();

        let timeutc = hours + ":" + min;

        var tmonth = sdateutc.getMonth() + 1;

        let t = sdateutc.toDateString().split(" ");
        let temp = t[2] + "-" + tmonth + "-" + t[3];

        let t2month = enddateutc.getMonth() + 1;

        let t2 = enddateutc.toDateString().split(" ");
        let temp2 = t2[2] + "-" + t2month + "-" + t2[3];

        let formData = new FormData()

        formData.append('file',file);
        formData.append('yourname',yourname);
        formData.append('topic',topic);
        formData.append('description',description);
        formData.append('noofpartner',noofpartner);
        formData.append('noofdays',noofdays);
        formData.append('startdate',startdate);
        formData.append('starttime',starttime);
        formData.append('timezone',timezone);
        formData.append('sdateutc',temp);
        formData.append('enddateutc',temp2);
        formData.append('timeutc',timeutc);
        formData.append('categoryId',categoryId);
        formData.append('nameCategory',nameCategory);
        formData.append('timeline',JSON.stringify(timeline));

        const result = await publishTopic(formData);


        if(result.STATUSCODE === 0) {

          let topcis = {
            userid:userdetails.userid,
            topicId:result.PAYLOAD,
            topciName:topic
          }

          socket.emit("publish", {
            topcis
          })

          setDoneflag(false);
          setpubFlag(false);
        }


    }

    const handleBack = () => {
        navigate(-1);
    }

  useEffect( async () => {

     let date = new Date(publish.startdate);

     const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
     }).replace(/ /g, '-');

     setStartdate(formattedDate);
     setStarttime(publish.starttime);
     setNoofdays(publish.noofdays);
     setNoofpartner(publish.noofpartner);
     setYourname(publish.yourname);
     setTopic(publish.topic);
     setTimeline(publish.timeline);
     setDescription(publish.description)
     setTimezone(publish.timezone)
     setGmtoffset(publish.gmtoffset);
     setCategoryId(publish.topic_categoryid);
     setNameCategory(publish.topic_categoryname);

      if(publish.filepath) {
        let compressFile = await handleCompress(publish.filepath,500,550);
        setFile(compressFile);
        setfileFlag(true)
      } else {
        setFile("nofile");
      }

   }, [publish]);

  return (
     <div className="Publisherr-wrapper">
    {doneflag ?
      <>
      <div className="Publisherr-header">
          <div className="Publisherr-titlearrow" onClick={handleBack} >
              <div className="Publisherr-arrowwrapper">
                   <BsArrowLeftShort/>
                </div>
          </div>

          {fileFlag ?
              <div className="Publisherr-img-wrapper">
                <img  className="Publisherr-img" src={URL.createObjectURL(file)} />
              </div>
            : <div className="Publisherr-img-wrapper">
                 <img className="Publisherr-img" src={Prioryou} />
               </div>
           }

           <div className="Publisherr-titlearrowhide">
               <div className="Publisherr-arrowwrapper">
                    <BsArrowLeftShort/>
                 </div>
           </div>
      </div>

        <div className="Publisherr-divider">
          <div className="Publisherr-line"> </div>
        </div>

      <div className="Publisherr-body">

          <div class="Publisherr-title">T o p i c</div>
          <div class="Publisherr-subtitle">{topic}</div>

          <div class="Publisherr-space"></div>

          <div class="Publisherr-title">W i t h</div>
          <div class="Publisherr-subtitle">{yourname}</div>

          <div class="Publisherr-space"></div>

          <div class="Publisherr-title">W a n t</div>
          <div class="Publisherr-subtitle">{noofpartner} Friends</div>

          <div class="Publisherr-space"></div>

          <div class="Publisherr-title">F o r</div>
          <div class="Publisherr-subtitle">{noofdays} days</div>

          <div class="Publisherr-space">&nbsp;</div>
          <div class="Publisherr-title">T i m e z o n e</div>
          <div class="Publisherr-subtitle">{timezone}</div>

          <div class="Publisherr-space">&nbsp;</div>
          <div class="Publisherr-title">S t a r t @ f o r m</div>
          <div class="Publisherr-subtitle">{startdate} @ {starttime}</div>

          <div className="Publisherr-empty">{pubFlag ? <Classicspiner /> : null }</div>

       {pubFlag ?
          <div className="Publisherr-post">
               &nbsp;&nbsp;Publish&nbsp;&nbsp;
          </div>
          :
          <div className="Publisherr-post" onClick={() => handlePublish()}>
               &nbsp;&nbsp;Publish&nbsp;&nbsp;
          </div>
        }



      </div> </>  :

      <Pbsuccess done={done} /> }

     </div>
  )
}

export default Publisherr;

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  topiclist:null,
  clearFlag:false,
  categoryId:null,
};

const categoryReducer = (state = initialState, action) => {

  switch (action.type) {

      case actionTypes.ADD_TAG:

        return {
          ...state,
          topiclist:action.payload
        };

      case actionTypes.SELECT_TAG:

         let topiclist_sel = [...state.topiclist];

         let topic_index = topiclist_sel.findIndex(item => item.selectedFlag == true);

     if(topic_index !== -1) {
         topiclist_sel[topic_index].selectedFlag = false;
    }

         topiclist_sel[action.payload].selectedFlag = true;

        return {
          ...state,
          topiclist:topiclist_sel
        };

      case actionTypes.REMOVE_TAG:

          let topiclist_rmv = [...state.topiclist];
          topiclist_rmv[action.payload].selectedFlag = false;

        return {
          ...state,
          topiclist:topiclist_rmv
        };

      case actionTypes.CLEAR_FLAG:

        return {
          ...state,
          clearFlag:action.payload
       };

      case actionTypes.REST_ALL:

         return {
           clearFlag:false,
           categoryId:null,
           topiclist:action.payload
        };

      case actionTypes.ADD_CATEGORYID:

         return {
             ...state,
             categoryId:action.payload
          };

      case actionTypes.REMOVE_ALL_CATEGORYID:

           return {
                 ...state,
                 categoryId:null,
            };


    default:
      return state;
  }
};

export default categoryReducer;
